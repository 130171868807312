/*
* 업무구분: 업무공통
* 화면 명: MSPBC661P
* 화면 설명: 전화받기서비스 사용여부 팝업
* 화면 접근권한:
*/
<template>
  <ur-page-container title="AI활동비서 전화받기 안내" :show-title="false" class="msp" type="popup">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="" direction="column">
        <!-- 본문내용 -->
        <mo-bottom-sheet ref="nsbottomsheet" :close-btn="true" class="ns-bottom-sheet" @closed="fn_close">
          <template v-slot:title><div class="campaignTitle">AI활동비서 전화받기 서비스가 오픈되었습니다!</div></template>
          <div class="content-area">
            <div class="wrap-info-process">
              <ul>
                <li>
                  <p class="exp">고객 전화가 걸려올 때 간단한 정보를 확인하고 고객카드로 바로 이동하실 수 있어요.</p>
                  <p class="exp">전화받기 서비스를 이용하시겠습니까?</p>
                  <p class="img"><img src="@/assets/img/content/callingSample.png" alt="전화받기 안내화면"></p>
                  <p class="tip">※ 내 스마트폰 기기에 저장되어 있고 사랑On 고객카드에 저장된 휴대폰 번호만 사용가능</p><!-- 2024.9.30 :chan -->
                </li>
              </ul>
            </div>
          </div>

          <template v-slot:action>
            <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
              <div class="relative-div">
              <mo-button componentid="mo_button_004" color="primary" shape="solid" size="medium" class="ns-btn-round white"  @click="$emit('onPopupClose')">미사용</mo-button>
                <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="$emit('onPopupConfirm')">사용</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>
        <!-- 본문내용 끝 -->
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>

</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "",
    screenId: "",
    components: {},
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      // 디바이스백키 비활성화
      window.fdpbridge.exec('backkeyCtrlPlugin', {value: false}, () => {}, () => {})
    },
    mounted () {
      // Alert 노출(임시)
      this.$refs.nsbottomsheet.open();
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {     
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {      
      openPop() { this.$refs.nsbottomsheet.open(); },
      closePop() { this.$refs.nsbottomsheet.close(); },
      fn_close() {
        this.$emit('onPopupExit')
      }
    }
  };
</script>
<style scoped>
/* 2024.9.30 :chan */
.campaignTitle {width:100%; padding-right:30px}
.msp .ns-bottom-sheet .content-area {margin-top:-20px}
.msp .wrap-info-process {margin:0}
.msp .wrap-info-process .exp + .exp {margin-top:15px}
.msp .wrap-info-process .tip {font-size:0.8125rem; color:#6B7684; margin-top:30px}
.msp .wrap-info-process .img {margin-top:40px}
.msp .wrap-info-process img {max-width:45vw; min-width:40vw}
@media (min-width:500px) and (max-width:1000px){
  .msp .wrap-info-process img {max-width:40vw; min-width:35vw}
  .msp .wrap-info-process .tip {margin-top:10px}
}
@media (min-width:1000px){
  .msp .wrap-info-process img {max-width:20vw; min-width:20vw}
  .msp .wrap-info-process .tip {margin-top:10px}
}
</style>